import React from 'react';
import RecHeaderAndFooter from '../../layouts/RecHeaderAndFooter';
import Header from '../../components/recrutement/Index/Header';
import Chasseur from '../../components/recrutement/Index/Chasseur';
import BestProfile from '../../components/recrutement/Index/BestProfile';
import How from '../../components/recrutement/Index/How';
import { HeadFC } from 'gatsby';

export default function Recrutement() {
  return (
    <RecHeaderAndFooter>
      <Header />
      <Chasseur />
      <BestProfile />
      <How />
    </RecHeaderAndFooter>
  );
}

export const Head: HeadFC = () => <title>Recrutement | Digit Universe</title>