import React from 'react'
import { css, cx } from '../../../../styled-system/css'
import { Link } from 'gatsby';

import RecLoupe from "../../../images/RecLoupe.svg"
import RecCandidat from "../../../images/RecCandidat.svg"
import RecSelectCand from "../../../images/RecSelectCand.svg"
import RecPresentation from "../../../images/RecPresentation.svg"
import RecIntegration from "../../../images/RecIntegration.svg"
import RecSpacer from "../../../images/RecSpacer.svg"

const dataURItoBlob = (dataURI: string) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

const Test = () => {
    const blob = dataURItoBlob(RecSpacer);
    const url = URL.createObjectURL(blob);

    return (
        <div className={css({
            margin: "2.5rem 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5rem",
            lgDown: {
                gap: "2.5rem",
            }
        })}>
            <div className={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10rem",
                lgDown: {
                    flexDirection: "column",
                    gap: "2.5rem",
                }
            })}>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    flex: "1",
                    position: "relative",
                })}>
                    <div className={css({
                        position: "absolute",
                        top: "30%",
                        transform: "translateY(-50%)",
                        right: "-10rem",
                        width: "10rem",
                        height: "2rem",
                        lgDown: {
                            display: "none",
                        }
                    })}>
                        <img className={css({
                            width: "100%",
                            height: "100%",
                        })} src={url} alt="" />
                    </div>
                    <img src={RecLoupe} alt="" />
                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <h3 className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                        })}>Audit de vos besoins</h3>
                        <p className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1rem",
                            color: "#7983A7"
                        })}>Nous analysons les besoins spécifiques de votre entreprise pour mieux cibler les profils recherchés.</p>
                    </div>
                </div>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    flex: "1",
                    position: "relative",
                })}>
                    <div className={css({
                        position: "absolute",
                        top: "30%",
                        transform: "translateY(-50%)",
                        right: "-10rem",
                        width: "10rem",
                        height: "2rem",
                        lgDown: {
                            display: "none",
                        }
                    })}>
                        <img className={css({
                            width: "100%",
                            height: "100%",
                        })} src={url} alt="" />
                    </div>
                    <img src={RecCandidat} alt="" />
                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <h3 className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                        })}>Indentification des candidats</h3>
                        <p className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1rem",
                            color: "#7983A7"
                        })}>Création d'une liste restreinte de candidats anonymes hautement qualifiés adaptés à vos exigences.</p>
                    </div>
                </div>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    flex: "1",
                })}>
                    <img src={RecSelectCand} alt="" />
                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <h3 className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                        })}>Selection des candidats</h3>
                        <p className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1rem",
                            color: "#7983A7"
                        })}>Je réalise des entretiens et  sélectionne les meilleurs candidats pour votre entreprise.</p>
                    </div>
                </div>
            </div>
            <div className={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10rem",
                width: "60%",
                lgDown: {
                    flexDirection: "column",
                    gap: "2.5rem",
                    width: "100%"
                }
            })}>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    flex: "1",
                    position: "relative",
                })}>
                    <div className={css({
                        position: "absolute",
                        top: "30%",
                        transform: "translateY(-50%)",
                        right: "-10rem",
                        width: "10rem",
                        height: "2rem",
                        lgDown: {
                            display: "none",
                        }
                    })}>
                        <img className={css({
                            width: "100%",
                            height: "100%",
                        })} src={url} alt="" />
                    </div>
                    <img src={RecPresentation} alt="" />
                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <h3 className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                        })}>Présentation anonyme</h3>
                        <p className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1rem",
                            color: "#7983A7"
                        })}>Chaque candidat sélectionné est présenté anonymement avec une évaluation complète de ses compétences et de son potentiel.</p>
                    </div>
                </div>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1rem",
                    flex: "1",
                })}>
                    <img src={RecIntegration} alt="" />
                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <h3 className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "600",
                            fontSize: "1.5rem",
                        })}>Intégration du candidat</h3>
                        <p className={css({
                            textAlign: "center",
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1rem",
                            color: "#7983A7"
                        })}>Suivi personnalisé du processus d'intégration pour assurer une transition fluide et efficace.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const How = () => {
    return (
        <div className={css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5rem 0",
            lgDown: {
                margin: "2.5rem",
            }
        })}>
            <div className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                gap: "1rem",
            })}>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80%",
                })}>
                    <h2 className={css({
                        textAlign: "left",
                        color: "#000",
                        fontSize: "2.25rem",
                        fontFamily: "Assistant !",
                        fontWeight: "600",
                        marginBottom: "1rem",
                        lgDown: {
                            fontSize: "1.75rem",
                            textWrap: "nowrap",
                            textAlign: "center",
                        }
                    })}>
                        Comment nous vous{" "}
                        <span className={css({
                            color: "#37BBEF",
                            lgDown: {
                                display: "block"
                            }
                        })}>accompagnons</span>
                    </h2>
                    <p className={css({
                        fontFamily: "Assistant !",
                        fontSize: "1rem",
                        fontWeight: "400",
                        color: "#7983A7",
                        textAlign: "center",
                        maxWidth: "45%",
                        lgDown: {
                            maxWidth: "100%",
                        }
                    })}>Après avoir discuté et convenu ensemble, le processus d'accompagnement débute de la manière suivante :</p>
                </div>

                <Test />

                <Link to={"/recrutement/contact"}>
                    <button className={css({
                        color: 'white',
                        background: '#006BFF',
                        padding: "1rem 2rem",
                        fontFamily: "Assistant !",
                        fontWeight: "600",
                        fontSize: "1.25rem",
                        cursor: "pointer",
                        lgDown: {
                            padding: ".75rem",
                            fontSize: ".9rem",
                        }
                    })}>
                        Contacter
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default How;