import React from 'react'
import { css } from '../../../../styled-system/css'
import Logo from "../../../images/RecImgAssis.svg"

const Chasseur = () => {
    return (
        <div className={css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "5rem 0",
            lgDown: {
                margin: "2.5rem",
            }
        })}>
            <div className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                lgDown: {
                    width: "100%",
                    gap: "0.5rem"
                },
                gap: "1rem",
            })}>
                <h2 className={css({
                    textAlign: "left",
                    color: "#000",
                    fontSize: "2.25rem",
                    fontFamily: "Assistant !",
                    fontWeight: "600",
                    lgDown: {
                        fontSize: "1.75rem",
                    }
                })}>
                    Votre chasseur de tête{" "}
                    <span className={css({
                        color: "#37BBEF"
                    })}>IT</span>
                </h2>
                <div className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "5rem",
                    width: "100%",
                    lgDown: {
                        flexDirection: "column-reverse",
                        gap: "2.5rem"
                    }
                })}>
                    <div className={css({
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    })}>
                        <p className={css({
                            fontFamily: "Assistant !",
                            fontWeight: "400",
                            fontSize: "1.25rem",
                            lgDown: {
                                textAlign: "center",
                                fontSize: "1rem",
                            }
                        })}>Je suis Quentin Barroin, chasseur de tête avec 15 ans d'expérience en IT. Ayant occupé divers rôles techniques, je possède un réseau étendu et une profonde compréhension des défis uniques du secteur. Mon approche du recrutement de talents IT est fondée sur des valeurs humanistes, traitant chaque mission comme si j'engageais pour ma propre entreprise. Je me spécialise en sourcing et sélection de profils IT, offrant des services de recrutement qui alignent parfaitement compétences et opportunités professionnelles.</p>
                    </div>
                    <div className={css({
                        flex: "1",
                    })}>
                        <div className={css({
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        })}>
                            <img className={css({
                                height: "100%",
                            })} src={Logo} alt="Quentin Assis" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chasseur;