import React from 'react'
import { css } from '../../../../styled-system/css'
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

// @ts-expect-error
import Logo from '../../../images/encart-recrutement-2-personnes.svg'
// @ts-expect-error
import Hour from '../../../images/RecHour.svg';
// @ts-expect-error
import Expertise from '../../../images/RecExpertise.svg';
// @ts-expect-error
import Oreille from '../../../images/RecOreille.svg';

const BestProfile = () => {
    return (
        <div className={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#EAF1FE",
            padding: "0.25rem 0",
            width: "100%",
            lgDown: {
                padding: "2rem 0",
            }
        })}>
            <div className={css({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5rem",
                width: "80%",
            })}>
                <div className={css({
                    flex: "1",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    lgDown: {
                        display: "none",
                    }
                })}>
                    <img src={Logo} alt="Quentin Assis 2" />
                </div>
                <div className={css({
                    display: "flex",
                    flexDirection: "column",
                    flex: "1",
                    gap: "1rem",
                    lgDown: {
                        alignItems: "center",
                    }
                })}>
                    <h2 className={css({
                        textAlign: "left",
                        color: "#000",
                        fontSize: "2.25rem",
                        fontFamily: "Assistant !",
                        fontWeight: "600",
                        lineHeight: "1",
                        lgDown: {
                            textAlign: "center",
                        }
                    })}>
                        Les meilleurs profils IT pour votre entreprise
                    </h2>
                    <h3 className={css({
                        textAlign: "left",
                        color: "#7983A7",
                        fontSize: "1.25rem",
                        fontFamily: "Assistant !",
                        fontWeight: "400",
                        lineHeight: "1",
                        lgDown: {
                            marginTop: "1rem",
                            textAlign: "center",
                        }
                    })}>
                        Quels sont les avantages de déléguer votre recrutement IT à un expert comme moi ?
                    </h3>

                    <div className={css({
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1.5rem",
                        marginBottom: "1.5rem",
                        gap: "1rem",
                        lgDown: {
                            gap: "2.5rem",
                        }
                    })}>
                        <div className={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            lgDown: {
                                flexDir: "column",
                                textAlign: "center",
                                gap: "1rem"
                            }
                        })}>
                            <div className={css({
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: "0.15",
                            })}>
                                <div className={css({
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    w: "75%",
                                    h: "75%",
                                    lgDown: {
                                        w: "100%",
                                        h: "100%",
                                    }
                                })}>
                                    <img className={css({
                                        background: "#fff",
                                        padding: ".5rem",
                                        borderRadius: "50%",
                                    })} src={Hour} alt="" />
                                </div>
                            </div>
                            <div className={css({
                                flex: "0.85"
                            })}>
                                <h3 className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "600",
                                    fontSize: "1.5rem",
                                    lgDown: {
                                        fontSize: "1.25rem",
                                    }
                                })}>Gain de temps</h3>
                                <p className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "400",
                                    fontSize: "1.25rem",
                                    color: "#7983A7",
                                    lgDown: {
                                        fontSize: "1rem",
                                    }
                                })}>Déléguer votre recrutement IT libère des ressources internes, augmentant donc votre productivité et réduisant les coûts.</p>
                            </div>
                        </div>
                        <div className={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            lgDown: {
                                flexDir: "column",
                                textAlign: "center",
                                gap: "1rem"
                            }
                        })}>
                            <div className={css({
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: "0.15",
                            })}>
                                <div className={css({
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    w: "75%",
                                    h: "75%",
                                    lgDown: {
                                        w: "100%",
                                        h: "100%",
                                    }
                                })}>
                                    <img className={css({
                                        background: "#fff",
                                        padding: ".5rem",
                                        borderRadius: "50%",
                                    })} src={Expertise} alt="" />
                                </div>
                            </div>
                            <div className={css({
                                flex: "0.85"
                            })}>
                                <h3 className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "600",
                                    fontSize: "1.5rem",
                                    lgDown: {
                                        fontSize: "1.25rem",
                                    }
                                })}>15 ans d’expertise</h3>
                                <p className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "400",
                                    fontSize: "1.25rem",
                                    color: "#7983A7",
                                    lgDown: {
                                        fontSize: "1rem",
                                    }
                                })}>Mon expertise en IT me permet de comprendre parfaitement vos besoins pour choisir les candidats adéquats.</p>
                            </div>
                        </div>
                        <div className={css({
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            lgDown: {
                                flexDir: "column",
                                textAlign: "center",
                                gap: "1rem"
                            }
                        })}>
                            <div className={css({
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: "0.15",
                            })}>
                                <div className={css({
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    w: "75%",
                                    h: "75%",
                                    lgDown: {
                                        w: "100%",
                                        h: "100%",
                                    }
                                })}>
                                    <img className={css({
                                        background: "#fff",
                                        padding: ".5rem",
                                        borderRadius: "50%",
                                    })} src={Oreille} alt="" />
                                </div>
                            </div>
                            <div className={css({
                                flex: "0.85"
                            })}>
                                <h3 className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "600",
                                    fontSize: "1.5rem",
                                    lgDown: {
                                        fontSize: "1.25rem",
                                    }
                                })}>Ecoute attentive</h3>
                                <p className={css({
                                    fontFamily: "Assistant !",
                                    fontWeight: "400",
                                    fontSize: "1.25rem",
                                    color: "#7983A7",
                                    lgDown: {
                                        fontSize: "1rem",
                                    }
                                })}>En plus d'écouter vos besoins, je me met à la place de votre entreprise pour bien cerner vos besoins.</p>
                            </div>
                        </div>
                    </div>
                    <section id="accompagnement"></section>

                    {(process.env.GATSBY_NO_PROFILES === 'true') ? (
                        <div>
                            <button className={css({
                                color: 'white',
                                background: '#006BFF',
                                padding: "1rem 2rem",
                                fontFamily: "Assistant !",
                                fontWeight: "600",
                                fontSize: "1.25rem",
                                cursor: "pointer",
                                lgDown: {
                                    padding: ".75rem",
                                    fontSize: ".9rem",
                                }
                            })} onClick={() => scrollTo("#accompagnement")}>
                                Découvrir l'accompagnement
                            </button>
                        </div>
                    ) : (
                        <Link to={"/recrutement/profils"} className={css({
                            marginLeft: "6.5rem",
                            lgDown: {
                                marginLeft: "0",
                            }
                        })}>
                            <button className={css({
                                color: 'white',
                                background: '#006BFF',
                                padding: "1rem 2rem",
                                fontFamily: "Assistant !",
                                fontWeight: "600",
                                fontSize: "1.25rem",
                                cursor: "pointer",
                                lgDown: {
                                    padding: ".75rem",
                                    fontSize: ".9rem",
                                }
                            })}>
                                Découvrir les profils
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BestProfile;